import React from 'react';
import { Wrapper } from './styled';

function Loader() {
  return (
    <Wrapper className="lds-grid">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </Wrapper>
  );
}

export { Loader };
