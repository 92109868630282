import React, { CSSProperties, useState } from "react";
import Image from "next/image";
import Box from "@mui/material/Box";
import eyeOff from "../../../public/staticfiles/icons/eye-off.svg";
import eye from "../../../public/staticfiles/icons/eye.svg";
import {
  EyeWrapper,
  StyledTextField,
  Wrapper,
  HelperText,
  Label,
  StartAdornment,
  EndAdornment,
} from "./styled";

interface StyledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange?: (e: any) => void;
  fullWidth?: boolean;
  name: string;
  label?: React.ReactNode | string;
  placeholder?: string;
  isPassword?: boolean;
  type?: string;
  error?: boolean;
  helperText?: React.ReactNode | string;
  cs?: CSSProperties;
  disabled?: boolean;
  margin?: boolean;
  startAdornment?: any;
  endAdornment?: any;
}

const StyledInput = React.forwardRef<HTMLInputElement, StyledInputProps>(
  (
    {
      onChange,
      fullWidth,
      name,
      label,
      placeholder,
      error,
      helperText,
      isPassword,
      type = "text",
      cs,
      disabled,
      margin,
      startAdornment,
      endAdornment,
      ...props
    },
    ref,
  ) => {
    const [isRevealed, setIsRevealed] = useState(false);

    return (
      <Wrapper style={cs} disabled={disabled} $margin={margin}>
        <Box position="relative">
          {label && <Label>{label}</Label>}
          {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
          <StyledTextField
            ref={ref}
            $fullWidth={fullWidth}
            placeholder={placeholder}
            name={name}
            $error={error}
            type={(isPassword && (isRevealed ? "text" : "password")) || type}
            onChange={onChange}
            style={{ textIndent: startAdornment ? "24px" : 0 }}
            {...props}
          />
          {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
        </Box>
        {Boolean(helperText) && <HelperText error={error}>{helperText}</HelperText>}
        {!error && isPassword && (
          <EyeWrapper>
            <Image
              src={isRevealed ? eyeOff : eye}
              onClick={() => setIsRevealed((prev) => !prev)}
              alt="eye"
            />
          </EyeWrapper>
        )}
      </Wrapper>
    );
  },
);

StyledInput.displayName = "StyledInput";

export { StyledInput };
