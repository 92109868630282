import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { NextApiRequest, NextApiResponse } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../context/AmplifyContext";
import { NeutralLoadingOverlay } from "../../src/components/AppComponents/LoadingOverlay";
import DefaultButton from "../../src/components/DefaultButton";
import Loader from "../../src/components/Loader";
import StyledInput from "../../src/components/StyledInput";
import { useWhitelabeling } from "../../src/hooks/useWhitelabeling";
import { getCurrentUserServer } from "../../src/utils/amplifyServerUtils";
import { Card } from "../../src/utils/styled";
import { ForgotPasswordButton, LoaderWrapper } from "../../styles/login";

enum FormNames {
  Email = "username",
  Password = "password",
}

function Login() {
  const [defaultValues, setDefaultValues] = useState({
    username: "",
    password: "",
  });
  const [isLoadingPrefill, setIsLoadingPrefill] = useState(true);
  const router = useRouter();
  const { login } = useAuthContext();
  const { logo, data } = useWhitelabeling();
  const { partnerId, isLoading } = data;
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<{ username: string; password: string }>();

  const onSubmit = handleSubmit((formData) => {
    login({ ...formData, username: formData?.username?.trim() });
  });

  useEffect(() => {
    if (router.query.email && router.query.password) {
      setDefaultValues({
        username: router.query.email as string,
        password: router.query.password as string,
      });

      login({ username: router.query.email as string, password: router.query.password as string });
    }

    setIsLoadingPrefill(false);
  }, [router]);

  if (data.isLoadingPartnerId || (data.partnerId && !data.response) || isLoadingPrefill) {
    return <NeutralLoadingOverlay fullPage />;
  }

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <Box
        sx={{
          backgroundColor: partnerId ? "var(--navbar)" : "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 8,
              userSelect: "none",
            }}
          >
            <img src={logo?.fullSizeDark} alt="logo" style={{ width: "200px", height: "auto" }} />
          </Box>
          <Card>
            {isSubmitting && (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            )}
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
                position: "relative",
                opacity: +!isSubmitting,
                pointerEvents: isSubmitting ? "none" : "auto",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 600,
                    userSelect: "none",
                  }}
                >
                  Sign in
                </Typography>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  mt: "10px",
                }}
              >
                <form onSubmit={onSubmit}>
                  <StyledInput
                    fullWidth
                    defaultValue={defaultValues.username}
                    placeholder="Email Address/Username"
                    error={Boolean(errors[FormNames.Email])}
                    helperText={errors[FormNames.Email]?.message as string}
                    {...register(FormNames.Email, {
                      required: "This field is required",
                    })}
                  />
                  <StyledInput
                    fullWidth
                    defaultValue={defaultValues.password}
                    placeholder="Password"
                    error={Boolean(errors[FormNames.Password])}
                    helperText={errors[FormNames.Password]?.message as string}
                    {...register(FormNames.Password, {
                      required: "This field is required",
                    })}
                    isPassword
                    type="password"
                  />
                  <Box sx={{ mt: 2 }}>
                    <DefaultButton
                      customStyles={{
                        width: "100%",
                        fontWeight: 600,
                        py: "8px",
                        backgroundColor: "var(--primary)",

                        "&:hover": {
                          backgroundColor: "var(--navbar)",
                        },
                      }}
                      label="Sign In"
                    />
                  </Box>
                </form>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mt: 2,
                  }}
                >
                  <ForgotPasswordButton onClick={() => router.push("/login/recovery")}>
                    Forgot Password?
                  </ForgotPasswordButton>
                </Box>
              </Box>
              <Divider sx={{ my: 3 }} />
              <DefaultButton
                variant="outlined"
                customStyles={{
                  width: "100%",
                  py: "8px",
                  fontWeight: 600,
                  textTransform: "initial",
                  borderColor: "var(--primary)",
                  color: "var(--primary)",

                  "&:hover": {
                    borderColor: "var(--navbar)",
                    color: "var(--navbar)",
                  },
                }}
                label="Create an Account"
                onClick={() => router.push("/register")}
              />
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
}

export default Login;

export async function getServerSideProps({
  req,
  res,
}: {
  req: NextApiRequest;
  res: NextApiResponse;
}) {
  const user = await getCurrentUserServer(req, res).catch((e: any) => {
    console.log(e);
  });
  if (user) {
    return {
      props: {},
      redirect: {
        destination: "/dashboard",
        permanent: false,
      },
    };
  }
  return { props: {} };
}
