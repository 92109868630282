import styled, { css } from "styled-components";

export const Label = styled.div`
  width: fit-content !important;
  transform: translateY(-60%);
  padding: 0 4px;
  text-transform: capitalize;
  left: 12px;
  background: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #253858;
  position: absolute;
  svg {
    margin-left: 4px;
    max-height: 16px;
    max-width: 16px;
  }
`;

export const HelperText = styled.div<{ error?: boolean }>`
  margin-top: 4px;
  padding-left: 14px;
  font-size: 14px;
  white-space: break-spaces;

  ${(props) =>
    props.error &&
    css`
      color: #f44336;
    `}
`;

export const Wrapper = styled.div<{ disabled?: boolean; $margin?: boolean }>`
  position: relative;
  margin-bottom: 16px;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
      user-select: none;
    `}

  ${(props) =>
    props.$margin &&
    css`
      margin: 0;
    `}
`;

export const EyeWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
  display: flex;
  height: calc(100% - 24px);
  img {
    cursor: pointer;
  }
`;

export const StyledTextField = styled.input<{
  $fullWidth?: boolean;
  $error?: boolean;
  readOnly?: boolean;
}>`
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  font-size: 16px;
  font-weight: 400;
  font-family: sofia-pro, sans-serif;
  background: transparent;
  line-height: 24px;
  height: 56px;

  &:focus,
  &:active {
    outline-color: var(--primary);
    border-color: var(--primary);
  }

  ${(props) =>
    props.$error &&
    css`
      border-color: #f44336 !important;
      color: #f44336 !important;

      &::placeholder {
        color: #f44336 !important;
      }

      &:focus,
      &:active {
        outline-color: #f44336 !important;
        border-color: #f44336 !important;
        color: #f44336 !important;
      }
    `}

  ${(props) =>
    props.$fullWidth &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.readOnly &&
    css`
      background-color: #f7f7f7;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const StartAdornment = styled.span`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);

  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EndAdornment = styled.span`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  font-family: sofia-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6b7280;
`;
