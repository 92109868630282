import type { ButtonTypeMap } from "@mui/material/Button";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { SxProps } from "@mui/system/styleFunctionSx";
import React, { memo } from "react";

// eslint-disable-next-line react/display-name
const DefaultButtonComp = React.forwardRef<
  HTMLButtonElement,
  ButtonTypeMap["props"] & {
    label: React.ReactNode;
    disabled?: boolean;
    customStyles?: SxProps;
    onClick?: (e?: any) => void;
    type?: "submit" | "button" | "reset" | undefined;
    fontWeight?: number;
    borderRadius?: string;
    isLoading?: boolean;
  }
>(
  (
    {
      label,
      disabled,
      endIcon,
      startIcon,
      customStyles,
      onClick,
      size,
      variant = "contained",
      type = "submit",
      color = "primary",
      isLoading,
      ...props
    },
    ref,
  ) => (
    <Button
      ref={ref}
      size={size}
      onClick={onClick}
      endIcon={endIcon}
      startIcon={startIcon}
      color={color}
      disabled={disabled}
      type={type}
      variant={variant}
      {...props}
      sx={{
        color: isLoading ? "transparent" : color,
        position: "relative",
        textTransform: "capitalize",
        fontFamily: "sofia-pro",
        borderRadius: "8px",
        fontWeight: "600",

        ".MuiButton-endIcon": {
          svg: {
            path: {
              fill: isLoading ? "transparent" : "var(--primary)",
            },
          },
        },
        ...customStyles,
      }}
    >
      {label}
      {isLoading && (
        <CircularProgress
          style={{
            position: "absolute",
            color: color === "error" ? "#f44336" : "#FFF",
            width: 20,
            height: 20,
          }}
        />
      )}
    </Button>
  ),
);

const DefaultButton = memo(DefaultButtonComp);

export { DefaultButton };
