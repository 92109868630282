import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import styled, { css } from "styled-components";

export const NoDataText = styled.div`
  color: #253858;
  font-size: 38px;
  font-weight: 500;
  margin: 0 auto;
  padding: 16px;
  text-align: center;
  &:before {
    content: "No Data";
    display: block;
  }
`;

export const ReconnectButtonsWrapper = styled(Box)({
  display: "flex",
  rowGap: "16px",
  flexDirection: "column",
  marginTop: 16,
});

export const ReportsCardsWrapperOld = styled.div<{ padding?: string; gap?: string }>`
  padding: ${({ padding }) => padding || "24px 0"};
  display: flex;
  gap: ${({ gap }) => gap || "20px"};
  box-sizing: border-box;
  flex-wrap: wrap;
  #cardWrapper {
    border: 2px solid #e6e8f0;
    box-shadow: none;
  }
  @media (max-width: 600px) {
    gap: 16px;
  }
`;

export const ReportsCardsWrapper = styled.div<{
  padding?: string;
  gap?: string;
}>`
  padding: ${({ padding }) => padding || "24px 0"};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${({ gap }) => gap || "24px"};
  box-sizing: border-box;
  #cardWrapper {
    box-shadow: none;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
`;

export const MetricsCardsWrapper = styled.div<{
  padding?: string;
  gap?: string;
}>`
  padding: ${({ padding }) => padding || "24px 0"};
  display: flex;
  flex-wrap: wrap;

  gap: ${({ gap }) => gap || "20px"};
  box-sizing: border-box;
  #cardWrapper {
    box-shadow: none;
  }
  @media (max-width: 600px) {
    gap: 16px;
  }
`;

export const BaseText = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 21px;
  color: rgba(66, 82, 110, 0.86);
`;

export const H3 = styled.h3`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 36px;
  color: #253858;
  margin: 0;
  padding: 0;
`;

export const Card = styled.div<{ overflowHidden?: boolean }>`
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 1px 1px rgb(100 116 139 / 10%), 0 1px 10px rgb(100 116 139 / 10%);
  color: #172b4d;
  height: 100%;

  ${(props) =>
    props.overflowHidden &&
    css`
      overflow: hidden;
    `}
`;

export const ConnectionButton = styled.button<{
  isConnected?: boolean;
  disabled?: boolean;
}>`
  width: fit-content;
  margin-top: auto;
  padding: 10px 22px;
  font-size: 15px;
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  background: var(--primary);
  border-radius: 8px;
  color: white;
  border: none;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    background-color: var(--navbar);
  }

  ${(props) =>
    props.disabled &&
    css`
      background: #9a9a9a;
      cursor: not-allowed;
      pointer-events: none;
    `}

  ${(props) =>
    props.isConnected &&
    css`
      background: #4caf50;
      &:hover {
        background: #4caf50;
      }
    `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .apexcharts-legend {
    .apexcharts-legend-series {
      display: flex !important;
      flex-direction: column;
      .apexcharts-legend-text {
        margin-top: 12px;
      }
    }
    .custom-marker {
      position: absolute;
      display: flex;
      width: 90%;
      height: 90%;
      right: 3px;
      top: 3px;
      border-radius: 5px;
      background-size: cover;
      background-image: url("../test.png");
    }
  }
`;

export const CenterWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWithHeaderCenterWrapper = styled(CenterWrapper)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  flex-direction: column;
`;

export const FullscreenWrapper = styled.div`
  height: 100vh;
  width: 100%;
`;

export const PieChartWrapper = styled(Grid)`
  @media (min-width: 1401px) {
    max-width: 50%;
  }
`;
