import Button from "@mui/material/Button";
import styled from "styled-components";

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

export const ForgotPasswordButton = styled(Button)`
  color: rgba(66, 82, 110, 0.86);
  font-size: 14px;
  font-weight: 400;
  background: transparent !important;
  text-transform: none;

  &:hover {
    text-decoration: underline;
  }
`;
